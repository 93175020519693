import React, {FC} from 'react';
import { useNavigate } from "react-router-dom";
import {storiesListClasses, StyledStoriesList} from "./StyledStoriesList";
import {Card, CardActionArea, CardContent, CardMedia, Button, Grid, Typography} from "@mui/material";
import {primary} from "../../common/theme";
import {IStory} from "../interfaces/IStory";

import location from "../../assets/location.png";
import { NavigationRoutes } from "../../routes";

interface IStoriesList  {
    stories: IStory[]
}

export const StoriesList: FC<IStoriesList> = ({stories}) => {
    const navigate = useNavigate();

    const pdfFileUrl = '/pdfs/LSP_MAGAZINE_VOLUME_I.pdf'; // Replace with the actual path to your PDF file
    const openPDF = () => {
        window.open(pdfFileUrl, '_blank');
    };

    const clickTag = (keyword: string) => (event: React.MouseEvent) => {
        event.stopPropagation();
        const formattedKeyword = keyword.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        navigate(`/${NavigationRoutes.Stories}/all/${formattedKeyword}`);
            
        window.scrollTo({
            top: document.getElementById('storiesList')?.offsetTop,
            behavior: 'smooth'
        });
    };

    // New function for handling story navigation
    const navigateToStory = (storyId: string) => {
        navigate(`/${NavigationRoutes.Story}/${storyId}`);
        window.scrollTo(0, 0);
    };
    
    return <StyledStoriesList container spacing={8} columnSpacing={4} className={storiesListClasses.root} id="storiesList">

        <Grid item sm={12} sx={{paddingTop:"10px!important"}}>
            <Typography variant={"h2"} component="div" sx={{display:"block", color: primary, margin: "70px 0 20px 0", zIndex: 100, textAlign:"left", fontSize:"36px", lineHeight:"42px", fontWeight:"400"}}>
            Download all the Stories
            </Typography>
        </Grid>
        <Grid item sm={12} sx={{paddingTop:"3px!important"}}>
            <Button variant={"contained"} sx={{padding:"14px 25px", height:"auto!important", minHeight:"48px", lineHeight:"20px"}} className={storiesListClasses.button} onClick={openPDF}>
                Volume 1, SRHR-UHC LSP Magazine, 2023
            </Button>
        </Grid>

        {stories.map((story) => (
            <Grid item xs={12} md={6} lg={4} key={story.id}>
                <Card className={storiesListClasses.card}>
                    <CardMedia
                        component="img"
                        image={story.image}
                        alt={story.imagealt}
                        className={storiesListClasses.cardImg}
                        onClick={() => navigateToStory(story.id)}
                        style={{ cursor: 'pointer' }}
                    />
                    {story.keywords && (
                        <Grid container className={storiesListClasses.keywords} wrap={"wrap"}>
                            {story.keywords.map((keyword, index) => (
                                <Typography key={index} className={storiesListClasses.keywordtag} onClick={clickTag(keyword)}>
                                    {keyword}
                                </Typography>
                            ))}
                        </Grid>
                    )}
                    <Grid container className={storiesListClasses.cardLocation}>
                        <img src={location} alt={"location"}/>
                        <Typography variant={"h6"}>{story.country}</Typography>
                    </Grid>
                    <CardContent className={storiesListClasses.cardContent}>
                        <Typography gutterBottom variant="h6" className="storyHeader">
                            {story.header}
                        </Typography>
                        <Button 
                            variant={"contained"} 
                            className={storiesListClasses.button}
                            onClick={() => navigateToStory(story.id)}
                        >
                            Read Full Story
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        ))}
    </StyledStoriesList>;
};
